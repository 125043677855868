(function(window){
    function ProductSlider(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ProductSlider.prototype = {
        container: undefined,
        scrollContent: undefined,
        pagerNext: undefined,
        pagerPrev: undefined,
        pagerSize: 0,
        slideBreakpoint: 1025,
        sliderContainer: undefined,
        type: 'home',
        containerWidth: 0,
        home_slider: false,
        products: {},
        init: function() {
            this.setElements();
            this.getInnerSize();
            this.setContainerWidth();
            this.setBoxSizes();

            this.slide(0);
            this.addDOMEvents();
        },
        setElements: function() {
            this.pagerNext = this.container.find('[data-purpose="next-pager"]');
            this.pagerPrev = this.container.find('[data-purpose="prev-pager"]');
            this.scrollContent = this.container.find('[data-purpose="product-list-container"]');
            this.sliderContainer = this.container.find('[data-purpose="product-list-outer"]');
            this.pagerSize = this.pagerPrev.outerWidth();
        },
        addDOMEvents: function() {
            var _self = this;

            _self.pagerNext.find('.arrow-button').click(function(){
                _self.slide(-1);
            });

            _self.pagerPrev.find('.arrow-button').click(function(){
                _self.slide(1);
            });

            $(document).on('shoppingstreet.window_width_changed', function(e, newWindowWidth){
                _self.getInnerSize();
                _self.setContainerWidth();
                if (newWindowWidth >= _self.slideBreakpoint) {
                    _self.slide(0);
                }
            });

            _self.container.find('[data-purpose="add-to-cart"]').click(function(e){
                e.preventDefault();
                var datas = _self.getProductData($(this).data('id'));
                if (datas) {
                    var variationData = {
                        name: datas.name,
                        id:datas.variation_id
                    };

                    var productData = {
                        id: datas.id,
                        unit: datas.unit
                    };
                    
                    if (typeof webshopUtils !== 'undefined') {
                        webshopUtils.addToCart(datas.variation_id, 1, variationData, productData);
                    }
                }
            });
        },
        setParams: function(params) {
            if (params.type) this.setType(params.type);
            if (typeof params.isHomeSlider !== 'undefined') this.setHomeSlider(params.isHomeSlider);
            if (params.products) this.setProducts(params.products);
        },
        setProducts: function(products) {
            this.products = $.extend({}, this.products, products);
        },
        setHomeSlider: function(is_home_slider) {
            this.home_slider = is_home_slider;
        },
        setType: function(type) {
            this.type = type;
        },
        getProductData: function(id) {
            return typeof this.products[id] === 'object' ? this.products[id] : undefined;
        },
        isHomeSlider: function() {
            return this.home_slider;
        },
        setBoxSizes: function(){
            var width = this.getInnerSize();

            if (width > windowWidth) {
                this.pagerNext.addClass('active');
            }
            if (windowWidth >= 1024) {
                // this.scrollContent.width(width);
            } else {
                // this.scrollContent.removeAttr('style');
                // this.scrollContent.width(width+30);
            }
        },
        getInnerSize: function() {
            var width = 0;
            
            this.container.find('.product-card').each(function(){
                width += $(this).outerWidth(true);
            });
            
            this.scrollContent.width(width);

            this.sliderWidth = width;
            return width;
        },
        getBoxWidth: function() {
            var children = this.scrollContent.children();
            if (children && children.length > 1) {
                return children.eq(1).outerWidth(true);
            }

            return 300;
        },
        slide: function(direction) {
            var innerWidth = this.getContainerWidth();
            var boxWidth = this.getBoxWidth();
            var stepSize = Math.floor(innerWidth / boxWidth) * boxWidth;
            
            var currentSlide = parseInt(this.scrollContent.data('slide-value'));
            var contentWidth = this.getSliderContentWidth();
            if (isNaN(currentSlide)) {
                currentSlide = 0;
            }
            
            currentSlide += (direction * stepSize);
            

            if (currentSlide >= 0 || innerWidth > contentWidth) {
                currentSlide = 0;
                if (this.pagerPrev.hasClass('active')) {
                    this.pagerPrev.removeClass('active');
                }
            }

            
            if (contentWidth < Math.abs(currentSlide)+innerWidth && contentWidth > innerWidth) {
                currentSlide = -1 * contentWidth + innerWidth;
                if (this.pagerNext.hasClass('active')) {
                    this.pagerNext.removeClass('active');
                }
            } else {
                if (!this.pagerNext.hasClass('active') && innerWidth < contentWidth) {
                    this.pagerNext.addClass('active');
                }
            }

            if (currentSlide < 0) {
                if (!this.pagerPrev.hasClass('active')) {
                    this.pagerPrev.addClass('active');
                }
            }
            

            if (windowWidth >= this.slideBreakpoint) {
                var additional = 0;

                this.scrollContent.css({
                    'transform': 'translateX('+ (currentSlide + additional) +'px)',
                });
            } else {
                this.scrollContent.css({
                    'transform': 'translateX(0px)',
                });
            }
            
            this.scrollContent.data('slide-value', currentSlide);
        },
        setContainerWidth: function() {
            this.containerWidth = this.sliderContainer.outerWidth();
        },
        getContainerWidth: function() {
            return this.containerWidth;
        },
        getSliderContentWidth: function() {
            return this.sliderWidth;
        },
    };
    
    window.ProductSlider = ProductSlider;

})(window);
